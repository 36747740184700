import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import $ from "jquery";

Rails.start();
ActiveStorage.start();
window.$ = $;
window.jQuery = $;
window.Rails = Rails;

$(document).ready(function () {
  $(document).on("click", ".js-close-modal", function (e) {
    e.preventDefault();
    $(this).parents(".modal__content-wrapper").parent().html(null);
  });

  $(document).on("click", "[data-toggle-popover]", function (e) {
    e.preventDefault();
    var elem = $(this);
    if (elem.hasClass("js-popover-on")) {
      if ($(e.target).parents(".popover").length === 0) {
        elem.removeClass("js-popover-on");
        $(elem.data("toggle-popover")).hide();
      }
    } else {
      elem.addClass("js-popover-on");
      $(elem.data("toggle-popover")).removeClass("is-hidden").show();
    }
  });

  $("body").on("click", function (e) {
    if (
      $(e.target).parents(".popover").length === 0 &&
      $(e.target).closest("[data-toggle-popover]").length === 0
    ) {
      $(".js-popover-on[data-toggle-popover]").each(function (i, popoverTrigger) {
        var trig = $(popoverTrigger);
        trig.removeClass("js-popover-on");
        $(trig.data("toggle-popover")).hide();
      });
    }
  });

  $("#login-form").on("submit", function (e) {
    $("input[type=submit]").removeClass("effect--shake");
    e.preventDefault();
    var form = $(this);
    $.ajax({
      type: form.attr("method"),
      url: form.attr("action"),
      data: form.serialize(),
    })
      .done(function (data) {
        $("#ajax-flash-container").html('');
        if (data.redirect) {
          window.location = data.redirect;
        } else {
          window.location = "/";
        }
      })
      .fail(function (data) {
        $("#ajax-flash-container").html('');
        $("input[type=submit]").removeAttr("disabled").removeClass("effect--shake");
        if (data.responseJSON && data.responseJSON.otp_required === true) {
          if ($(".js-otp").is(":visible")) {
            // If this panel is already visible, it means that the provided code is not correct
            $("input[type=submit]").addClass("effect--shake");
          }
          $(".js-regular-login").hide();
          $(".js-2fa, .js-otp").removeClass("is-hidden").show();
          $(".js-2fa input[type=text]").attr("autofocus", true).focus();
        } else {
          if (data.responseJSON.error && data.responseJSON.error != 'Invalid Email or password.') {
            var node = $(`<div class="inline-flash content-full ptb-xs inline-flash--error"><p class="ts-s">${data.responseJSON.error}</p></div>`)
            $("#ajax-flash-container").html(node);
          }
          window.setTimeout(function () {
            $("input[type=submit]").addClass("effect--shake");
            $(".js-2fa input[type=text]").focus();
          }, 10);
        }
      });
  });

  $(".js-recovery-code-trigger").on("click", function (e) {
    e.preventDefault();
    $("input[type=submit]").removeClass("effect--shake");
    $(".js-otp").hide();
    $(".js-recovery-code").removeClass("is-hidden").show();
    $(".js-2fa").find("input[type=text]").focus();
  });

  $(".js-otp-trigger").on("click", function (e) {
    e.preventDefault();
    $("input[type=submit]").removeClass("effect--shake");
    $(".js-recovery-code").hide();
    $(".js-otp").show();
    $(".js-2fa").find("input[type=text]").focus();
  });

  $(".js-toggle-sidebar").on("click", function (e) {
    e.preventDefault();
    $("input[type=submit]").removeClass("effect--shake");
    $("body").toggleClass("sidebar-shown");
  });

  $(document).on("click", "[data-toggle-class]", function (e) {
    e.preventDefault();
    this.classList.toggle($(this).data('toggle-class'));
  });
});
